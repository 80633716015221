import { Component } from '@angular/core';

@Component({
  selector: 'app-career-product',
  templateUrl: './career-product.component.html',
  styleUrls: ['./career-product.component.scss']
})
export class CareerProductComponent {

}
