<div class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Discover Mission</span>
                <h2>Why Our Platform Is Better</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>Most Promising Real Estate Company in Gurgaon NCR</h3>
                        <p>Myshopdeal is your premier choice in the real estate market for a multitude of compelling reasons. Our firm exclusively partners with A-category builders to bring you the latest and most exquisite properties on the market. This commitment to quality ensures that you're presented with a curated selection of top-tier properties that meet the highest standards in construction, design, and amenities.</p>
                        <a routerLink="/about" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read More</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>Most Promising Real Estate Company in Gurgaon NCR</h3>
                        <p>What sets Myshopdeal apart is our unwavering dedication to transparency and trustworthiness. We understand that purchasing a property is a significant investment, and we prioritise clear communication, honesty, and integrity throughout the entire process. From the initial property search to closing the deal, you can count on us to provide you with all the information you need to make informed decisions.</p>
                        <a routerLink="/about" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read More</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>Most Promising Real Estate Company in Gurgaon NCR</h3>
                        <p>Our commitment to customer satisfaction doesn't end with the sale. Myshopdeal takes pride in offering exceptional after-sales follow-up services. We continue to assist you even after the property transaction is complete, addressing any concerns or questions you may have. Our comprehensive support ensures that your real estate journey with us is seamless and hassle-free, making us the ideal choice for all your property needs. When you choose Myshopdeal, you're not just buying a property; you're investing in a partnership built on transparency, trust, and dedicated service.</p>
                        <a routerLink="/about" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read More</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>