<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area pt-70 pb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career"> Company</a></li>
                <li><a routerLink="/career-leadership"> Leadership</a></li>
                <li><a routerLink="/career-product-management"> Product Management</a></li>
                <li><a routerLink="/career-operation"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support" class="active"> Customer Support</a></li>
                <li><a routerLink="/career-joinus"> Join Us</a></li>
            </ul>
        </div>
    </div>
    </div>

<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/images/new-comers-3.png" alt="image">
                    <div class="wrap-shape">
                        <img src="assets/images/shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">Customer Support Manager</span>
                    <h3>MYSHOPDEAL</h3>
                    <h4>A NEW LANGUAGE OF FRIENDSHIP</h4>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Our customer support manager converts prospects into customers by showcasing appropriate primary residential properties which match their budget. They suggest a wide range of products that we offer.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    They use leads generated by the Marketing team as well as self-generating leads through local activities. They help in procuring relevant databases, doing on-ground activities in corporates, clubs, societies and events and converting them into customers.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Taking prospects for site visits in residential projects, answering all their queries throughout the process, liaising with Developers or with Myshopdeal's internal team for any clarification required for the same. They comply with all operational process.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Ensuring that prospects and customers are satisfied with their interaction with Myshopdeal at all times.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Providing after-sales services and assisting in Home Loan requirements of the customers..
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="new-comers-area ptb-70">
    <div class="container">
        <hr>
    </div>
</div>        
<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <h3>APPLY IF YOU HAVE....</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Graduation/ MBA degree.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Enjoy meeting new people and cracking a sale.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Positive attitude & confidence of doing 2-3 meetings a day.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Experience in direct sales (added advantage).
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Good communication skills.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Ability to understand requirements of prospects and an aptitude to understand and pitch property solutions to them.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    High levels of passion and ownership.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="new-comers-btn">
                        <a routerLink="/career-joinus" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Apply Now</span>
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pb-100">
                <div class="new-comers-image">
                    <img src="assets/images/free-trial.png" alt="image" style="width: 80%;float: right;">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>