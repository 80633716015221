<div class="hero-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <!-- <div class="hero-banner-content">
                        <owl-carousel-o [options]="missionSlides">
                            <ng-template carouselSlide>
                                <p>Lorem ipsum dolor sit amet.</p>
                            </ng-template>
                            <ng-template carouselSlide>
                                <h3>A place where you can achieve</h3>
                            </ng-template>
                        </owl-carousel-o>
                        </div> -->
                        <div class="hero-banner-content">
                            <span class="sub-title">We are</span>
                            <h1>Most Promising Real Estate Company in Gurgaon NCR</h1>
                            <p>We simplify real estate journey with clean, clear and transparent business model.</p>
                            <div class="btn-box">
                                <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Schedule a Call</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content">
                            <owl-carousel-o [options]="missionSlides">
                                <ng-template carouselSlide>
                                    <img src="assets/images/slider/slider1.jpg" alt="image">
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/slider/slider2.jpg" alt="image">
                                </ng-template>
                            </owl-carousel-o>
                            </div>
                        <!-- <div class="hero-banner-image text-center">
                            <img src="assets/images/slider/slider2.jpg" alt="image">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>