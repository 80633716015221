import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BlogDetailsComponent } from './pages/blogs/blog-details/blog-details.component';
import { PropertyComponent } from './pages/property/property.component';
import { PropertyDetailsComponent } from './pages/property-details/property-details.component';
import { CareerComponent } from './pages/career/career.component';
import { CareerCompanyComponent } from './pages/career/career-company/career-company.component';
import { CareerLeadershipComponent } from './pages/career/career-leadership/career-leadership.component';
import { CareerProductComponent } from './pages/career/career-product/career-product.component';
import { CareerOperationComponent } from './pages/career/career-operation/career-operation.component';
import { CareerMarketingComponent } from './pages/career/career-marketing/career-marketing.component';
import { CareerCustomerComponent } from './pages/career/career-customer/career-customer.component';
import { CareerJoinusComponent } from './pages/career/career-joinus/career-joinus.component';
import { HomeloanComponent } from './pages/homeloan/homeloan.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';

import { AllCategoryComponent } from './pages/all-category/all-category.component';
import { AllTestimonialsComponent } from './pages/all-testimonials/all-testimonials.component';
import { AllHappyClientsComponent } from './pages/all-happy-clients/all-happy-clients.component';
import { AllBrandsComponent } from './pages/all-brands/all-brands.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'blogs', component: BlogsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'blog-details/:data', component: BlogDetailsComponent},
    {path: 'property/:data', component: PropertyComponent},
    {path: 'residential-property/:data', component: PropertyComponent},
    {path: 'commercial-property/:data', component: PropertyComponent},
    {path: 'property-details/:data', component: PropertyDetailsComponent},
    {path: 'career', component: CareerComponent},
    {path: 'career-company', component: CareerCompanyComponent},
    {path: 'career-leadership', component: CareerLeadershipComponent},
    {path: 'career-product-management', component: CareerProductComponent},
    {path: 'career-operation', component: CareerOperationComponent},
    {path: 'career-marketing-strategy', component: CareerMarketingComponent},
    {path: 'career-customer-support', component: CareerCustomerComponent},
    {path: 'career-joinus', component: CareerJoinusComponent},
    {path: 'homeloan', component: HomeloanComponent},
    {path: 'login', component: LoginPageComponent},
    {path: 'register', component: RegisterPageComponent},

    {path: 'all-categories', component: AllCategoryComponent},
    {path: 'all-testimonials', component: AllTestimonialsComponent},
    {path: 'all-happy-clients', component: AllHappyClientsComponent},
    {path: 'all-brands', component: AllBrandsComponent},
    
   // {path: '**', component: ErrorPageComponent} //
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }