<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area ptb-70">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career"> Company</a></li>
                <li><a routerLink="/career-leadership" class="active"> Leadership</a></li>
                <li><a routerLink="/career-product-management"> Product Management</a></li>
                <li><a routerLink="/career-operation"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support"> Customer Support</a></li>
                <li><a routerLink="/career-joinus"> Join Us</a></li>
            </ul>
        </div>
</div>
</div>
<div class="my-dashboard-area pb-100">
   <div class="container">
<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader1.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>ANKIT MALIK</h3>
                <span class="sub-title">DIRECTOR</span>
                <p>The Director and soul of Myshopdeal left his secured job in Vatika with a dream to start a company of his own with a mission to help people find their dream home. With over 7 years of experience he has worked in many organisations like Axis Bank, Proptiger and Vatika. He had a great exposure to banking and financial sector and he learned about product branding when Axis bank rebranded themselves as - Badti ka naam zindegi. <br/>
                    He stepped into a completely different portfolio which was Real estate. Although it was different he was did not anyone let him down and he excelled in Proptiger where he won back to back Warrior Of The Month awards. He also won the top performer at PAN India level.This is when he learnt the potential of the real estate industry.<br/>
                    After this he joined as Asst Relationship Manager in Proptiger. Working in the builder lobby was a different experience.He could understand the other side of the story, from a builders perspective. It was a crucial year as he built a lot of relationships and network.
                    <br/>
                    After 6 months he realised that he has more to prove so Myshopdeal was born. Myshopdeal was based out of friendship trust and care with a new brand line- A new language of friendship. He believes that its not only about sale but the relationship which continues much after that.<br/>
                    His vision is to expand Myshopdeal and start new verticals - Home Automation, Home Interiors and Digital Marketing.</p>
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>IPSHITA RAY</h3>
                <span class="sub-title">HEAD CONTENT, SOCIAL MEDIA & HR</span>
                <p>She's one of the core team members and has been with Myshopdeal since inception. Keeping the entire team motivated and together is her goal. Coordination, communication, clear strategy and keeping everyone happy is her mantra to keep an organisation sustainable. She has an experience of over 9 years in various organisations. Keeping the website, social media and client's websites up to date with the right content is another essential role she plays.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader2.jpeg" alt="image">
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader3.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>VIVEK KUMAR</h3>
                <span class="sub-title">DIGITAL MARKETING HEAD</span>
                <p>As Independent Digital Marketing Consultant, Vivek has helped many small, medium and large firms to come up with the alien word of Digital Marketing and helped them look beyond traditional marketing solutions. He has also handled projects of a few SMEs and Start-ups and learnt a lot about organic and paid channels of Digital Marketing. He helps Myshopdeal with the setting up of various campaigns and marketing strategies which are essential in generating quality leads.</p>
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>ARCHNA PASSI</h3>
                <span class="sub-title">SALES MANAGER</span>
                <p>With 3 years experience in the Real Estate and Hospitality industry, she is a strong player and motivator of the sales team. She helps Myshopdeal in maintaining excellent relations with the builders, Clients and providing them effective services. Converting Prospects into customers by understanding their requirements and pitching them the right product which matches their investment Needs.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader-5.jpeg" alt="image">
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader6.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>DEEPAK</h3>
                <span class="sub-title">SENIOR GRAPHIC DESIGNER</span>
                <p>Mr Deepak is the creative mind behind our website and social media creatives designing. He is the head of the designing department.His box innovative designs for signage, billboards and other print material gets our clientele rolling. He has the power to convert every material into an engaging digital content. Mr Deepak handles our social media campaigns creatives, emails, website pages, infographics, ad banners, and beyond.</p>
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>DIVYA</h3>
                <span class="sub-title">DESIGN MANAGER</span>
                <p>A fashion designer and alumni of NIFT, Divya has been with Myshopdeal since 2 years and heads the graphical designing department. Her creativity keeps out clients engaged on social media platforms. From making, brochures, designing logos and portfolios for clients, brochures, pamphlets and making social media posts she has everything sorted.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader7.jpeg" alt="image">
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader8.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>PANKAJ KUMAR</h3>
                <span class="sub-title">WEBSITE DEVELOPMENT</span>
                <p>He is the person beyond our awesome websites. He has a strong grasp of current marketing tools and strategies and is able to lead integrated digital marketing campaigns from concept to execution. He builds websites using all the leading developing tools and coordinates with the team bringing out the best.</p>
            </div>
        </div>
    </div>
</div>

<hr>

<div class="instructor-details-desc">
    <div class="row">
        <div class="col-lg-8 col-md-8">
            <div class="instructor-details">
                <h3>ANKITA KATARIA</h3>
                <span class="sub-title">CONTENT MANAGER</span>
                <p>Passionate Content Writer who implements Creativity with the best of Marketing Strategies. A keen learner who always strives to bring innovation in her work with staying up to the date with the latest Market trends. Her journey at Myshopdeal from being a Fresher to now one with extensive experience in curating mind blowing content is commendable. A charming personality who follows her heart and staying Positive is her mantra for life.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-4">
            <div class="instructor-details-sidebar">
                <img src="assets/images/career-leader9.jpeg" alt="image">
            </div>
        </div>
    </div>
</div>



</div>
</div>

<div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>