import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../provider/service.service';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit  {
  missionSlides: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		// navText: [
		// 	"<i class='bx bx-left-arrow-alt'></i>",
		// 	"<i class='bx bx-right-arrow-alt'></i>"
		// ]
    }

    otherRooms = ['Pooja Rooms', 'Study Rooms', 'Servent Rooms', 'Store Rooms'];
    ageProperty = ['0-1 Years', '1-5 Years', '5-10 Years', '10-15 Years', '15+ Years'];
    expectedBy = ['3 Months', '6 Months', '1 Year', '2 Years', '3 Years', '4 Years', '5 Years', '6 Years', '7 Years', '8 Years', '9 Years', '10 Years'];
    facings = ['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'];
    openSides = [1,2, 3, 4];
    wallStatus = ['No Walls', 'Brick Walls', 'Cemented Walls', 'Plastered Walls'];
    flooringType = ['Marble', 'Concrete', 'Polished concrete', 'Granite', 'Ceramic', 'Mosaic', 'Cement', 'Stone', 'Vinyl', 'Wood', 'Vitrified', 'Spartex', 'IPSFinish', 'Others'];
    saftyMeasures = ['Fire Extinguisher', 'Fire Sensors', 'Sprinklers', 'Fire Hose'];
    pantryTypes = ['Private', 'Shared', 'Not-Available'];
    comWashroom = ['None', 'Shared', 'Private', 1, 2, 3, 4, '5+'];
    commQualityRatings = ['No Rating', '1 Star', '2 Star', '3 Star', '4 Star', '5 Star', '6 Star', '7 Star'];


    userDataPost={"userID":"", "o_companyID":1, "categorySlug":"residential" };
    constructor(private route: ActivatedRoute,
      private router: Router, public service: ServiceService) {
        
       }

       ngOnInit(): void {
        this.userDataPost.categorySlug = this.route.snapshot.paramMap.get('data');
       // console.log(this.userDataPost.categorySlug);
        this.get_property_on_category_slug();
      }

    openPropertyDetails(id){
      this.router.navigateByUrl('/property-details/' + id);
    }

    fslotsData:any; propertySet:any;  fimgURL:any; fploading:boolean = false;
    get_property_on_category_slug(){
         this.fploading = true;
         this.service.propertyData(this.userDataPost, 'get_property_on_category_slug').then((result) => {
           this.fslotsData = result;
           if (this.fslotsData.propertyData){
            this.fploading = false;
         this.propertySet = this.fslotsData.propertyData;
         for(let item of this.propertySet){
          item.amenities = JSON.parse(item.amenities);
          item.facilities = JSON.parse(item.facilities);
          item.facilitiesCount = item.facilities.length;
         }
         this.fimgURL = this.fslotsData.imgURL;
           }else{
          this.fploading = false;
         this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
           }
        }, (err) => { 
        this.fploading = false;
        this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
        });
      } 
  
  
  /* Alert Message */
  show_alert:boolean = false; message:any;
  alert(type, message){
    this.show_alert = true;
    this.message={'type':type,'text':message};
    setTimeout(() => {
      this.show_alert = false;
    }, 5000);
  }
  close_alert(){
    this.show_alert = false;
    this.message = '';
  }
  /* End of Alert Message */

}
