import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError, retry } from 'rxjs/operators';

let apiUrl = 'https://myshopdeal.co.in/real_estate_api/';
// let apiUrl = 'http://localhost/myapp/myshopdeals_api/';

@Injectable({
  providedIn: 'root'
})

export class ServiceService {

api: any;
constructor(private http: HttpClient){ 

}

postData(credentials, type){
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      this.http.post(apiUrl+'frontend.php/' + type, JSON.stringify(credentials), { headers: headers })
        .subscribe(res => {
          resolve(res);
          }, (err) => {
          reject(err);
      });
  });
}


propertyData(credentials, type){
  return new Promise((resolve, reject) => {
    let headers = new HttpHeaders();
    this.http.post(apiUrl+'property.php/' + type, JSON.stringify(credentials), { headers: headers })
      .subscribe(res => {
        resolve(res);
        }, (err) => {
        reject(err);
    });
});
}

}