<div class="page-title-area item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact Us</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:support@myshopdeal.co.in">support@myshopdeal.co.in</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Location Here</h3>
                    <p><a href="https://maps.app.goo.gl/qcoqvPrKxSfyThiU9" target="_blank">Myshopdeal, 7th floor, WeWork, HQ27 The Headquarters, B660, Sushant Loke Phase I, Sector-27, Gurugram, Haryana - 122009</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:919711750505">+91 97117 50505</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Drop us a Message</h2>
            <p>Feel free to reach out to us at any time. We're here to assist you with all your real estate needs. Whether you have questions about our properties, want to discuss your buying or selling goals, or simply need guidance in the world of real estate, our team is ready to provide expert advice and exceptional service.</p>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">Name is required.</div>
                                <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder="Your subject">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Your message..."></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/images/map.png" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.5514875506587!2d77.0490705!3d28.4629338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19769d6c0d7b%3A0x1da07c9ee31255fc!2sMyshopdeal!5e0!3m2!1sen!2sin!4v1696777133117!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>