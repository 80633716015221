<div class="page-title-area page-title-style-two item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">About Us</span>
                    <h2>MYSHOPDEAL</h2>
                    <h6>A NEW LANGUAGE OF FRIENDSHIP</h6>
                    <p>Myshopdeal simplify real estate journey with clean, clear and transparent business model. We have been awarded the “42 Most fundable startup of 2018”. Our team is extremely professional with an in depth knowledge of real estate industry and its products. We view a house as a “Home”, where families live, share their dreams and fall in love again. “You Demand” and we will deliver “ Dreams” “You Demand” and we will deliver “Honesty” “You Demand” and we will deliver ”Commitment” “You Demand” and we will deliver “Consistency” “You Demand” and we will deliver “Friendship” . Myshopdeal takes care of entire portfolio of residential , commercials and both in fresh and in resale market . With major tie ups with 400 A category builders you have a great variety of options to explore as per your requirements of plots , floors , apartments , villas , penthouses , duplexes in residential and foodcourts , studio apartments , offices , hypermarkets , retail , breweries , multiplexes , SCO in commercials .</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>We are among the top 42 most fundable start up of 2017. We have within a span of a few months successfully completed good bookings with top brands like Godrej , ATS, Eldeco and other Properties. We maintain complete transparency in real estate business like a reputed organisation We do not stop at sales , we believe in after sale relationship maintenance as well. We are friends for a lifetime...</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/images/why-choose-us.jpg" alt="image">
                    <img src="assets/images/about2.webp" alt="image" style="border-radius: 200px 0;">
                    <div class="text-box">
                        <div class="inner">
                            Trusted By
                            <span>75K+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ptb-100">
        <app-offer></app-offer>
     </div>

     <div class="partner-area bg-color ptb-70">
        <div class="container-fluid">
            <div class="section-title">
                <h2>Corporate Clients</h2>
            </div>
            <app-partner-style-one></app-partner-style-one>
        </div>
    </div>
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>SEARCH & SHORTING</h3>
                        <p>Finding your dream home isn't always easy. there's no doubt it can be time consuming and sometimes a little exhausting, and that's why we are there by your side to make all the difference</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>SITE VISIT</h3>
                        <p>We help in refining your wish list into a realistic a buyer's brief, preparing a shortlist of suitable properties,so that you can visit the sites which are just right for you and not go through the hassle.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>HOME LOAN ASSITANCE</h3>
                        <p>We assist you with home loans and registration because we believe that buying a home is one of the most important investments. so it should be the safest and best deal for you.</p>
                    </div>
                </div>
            </div>
            <div class="row pt-70">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>LEGAL ADVICE</h3>
                        <p>We provide comprehensive information along with the services of a legal advisor who offers genuine and unbiased advice and helps conclude the transaction.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>UNIT & BOOKING</h3>
                        <p>If there is this particular unit you have fallen in love with but you want to discuss it with your family and friends but at the same time you don't want to let it slip by.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>AFTER SALES SERVICES</h3>
                        <p>Our services to you continues even after the registry of the flat is over.we believe in long term and permanent relationship building. we believe in friendship.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-our-mission></app-our-mission>

<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>


<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<!-- <div class="partner-area ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div> -->

<app-become-instructor-partner></app-become-instructor-partner>