import { Component, OnInit  } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ServiceService } from '../../provider/service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  missionSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		]
    }


    userDataPost={"userID":"", "o_companyID":1, "categorySlug":"residential" };
    constructor(public service: ServiceService) {
	  }

	ngOnInit(): void {
			this.get_property_limit_5();
		  }
	

	fslotsData:any; propertySet:any;  fimgURL:any; fploading:boolean = false;
    get_property_limit_5(){
         this.fploading = true;
         this.service.propertyData(this.userDataPost, 'get_property_limit_5').then((result) => {
           this.fslotsData = result;
           if (this.fslotsData.propertyData){
            this.fploading = false;
         this.propertySet = this.fslotsData.propertyData;
         for(let item of this.propertySet){
          item.amenities = JSON.parse(item.amenities);
          item.facilities = JSON.parse(item.facilities);
          item.facilitiesCount = item.facilities.length;
         }
         this.fimgURL = this.fslotsData.imgURL;
           }else{
          this.fploading = false;
         this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
           }
        }, (err) => { 
        this.fploading = false;
        this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
        });
      } 

	    /* Alert Message */
  show_alert:boolean = false; message:any;
  alert(type, message){
    this.show_alert = true;
    this.message={'type':type,'text':message};
    setTimeout(() => {
      this.show_alert = false;
    }, 5000);
  }
  close_alert(){
    this.show_alert = false;
    this.message = '';
  }
  /* End of Alert Message */

}
