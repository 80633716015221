<section class="why-choose-us-area bg-e6fbff">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/imagesages/why-choose-us.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose Us</span>
                        <h2>Myshopdeal simplify real estate journey with clean, clear and transparent business model.</h2>
                        <p>Reasons for choosing myshopdeal are many but the best one is we are also following the concepts of trust , commitment , honesty and transparency . we all are following the practices which we learned from india best brand tata group , we are just trying to follow them . their leadership always inspire our organisation to work for the country and for its people being humble and simply sophisticated . this is what much needed step in digital real estate industry since long back . so just having a vast knowledge of the entire gurgaon delhi ncr portfolio myshopdeal upgrades their portfolio on daily basis so that knowledge can help our clients to get the best projects from expert guidance .</p>
                        <ul class="features-list">
                            <li><span><i class="flaticon-self-growth"></i> HOME LOAN ASSITANCE</span></li>
                            <li><span><i class="flaticon-clock"></i> LEGAL ADVICE</span></li>
                            <li><span><i class="flaticon-ebook"></i> UNIT & BOOKING</span></li>
                            <li><span><i class="flaticon-factory"></i> AFTER SALES SERVICES</span></li>
                        </ul>
                        <!-- <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/images/lang-shape2.png" alt="image"></div>
</section>