<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area pt-70 pb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career" class="active"> Company</a></li>
                <li><a routerLink="/career-leadership"> Leadership</a></li>
                <li><a routerLink="/career-product-management"> Product Management</a></li>
                <li><a routerLink="/career-operation"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support"> Customer Support</a></li>
                <li><a routerLink="/career-joinus"> Join Us</a></li>
            </ul>
        </div>
    </div>
</div>



<div class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/images/career.avif" class="shadow" alt="image">
                    <img src="assets/images/carrer2.png" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Company</span>
                    <h2>COME WORK WITH US AND YOU WILL LOVE IT!</h2>
                    <h6>Myshopdeal || A New Language of Friendship</h6>
                    <p>While you are working your hardest on opportunities that will define your career, We at Myshopdeal make sure your environment provides for you everything you deserve. It takes an entire team to be united behind something big. Together, we work hard, we laugh a lot, we brainstorm nonstop, we use hundreds of sticky notes and celebrate nonstop.</p>
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>BE A GOAL SETTER</h3>
                        <p>Encourage others to participate to their fullest Care for others and make them feel like they belong Listen, communicate openly and clearly.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>CHAMPION THE MISSION</h3>
                        <p>Prioritize work that advances the mission and positivity Build with the long-term in mind Actively participate in the community and culture.</p>
                    </div>
                </div>
            </div>
            <div class="row pt-70">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>BE BOLD & ORIGINAL</h3>
                        <p>Be bold and apply original thinking Imagine the ideal outcome Be resourceful to make the outcome a reality.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>EMBRACE THE ADVENTURE</h3>
                        <p>Be curious, ask for help, and demonstrate an ability to grow Own and learn from mistakes Bring joy and optimism to work.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>

<div class="my-dashboard-area ptb-70">
    <div class="container">
 <div class="instructor-details-desc">
     <div class="row">
         <div class="col-lg-5 col-md-5">
             <div class="instructor-details-sidebar">
                 <img src="assets/images/career-dashboard1.jpeg" alt="image">
             </div>
         </div>
         <div class="col-lg-7 col-md-7">
             <div class="instructor-details">
                 <h3>BUSINESS TEAM</h3>
                 <span class="sub-title">-----------------</span>
                 <p>Business team helps us get new business and collaborate with different vendors for providing various other services and privileges. They focus on improving the consumer experience and scaling it across the city. We are rapidly scaling our offerings and this is a great opportunity to lead an organization that is highly visible across the company.<br/>
                    The business team works closely with every product and builders. They make analysis and make reports to improve the business.
                    You must be comfortable interacting with customers at all levels. The ideal candidate will be highly motivated and have a passion for customers, technology, and problem solving. If you are self-driven, enjoy working in a team environment, have a pioneering spirit, and enjoy helping others be successful, Myshopdeal may be a fit for you.
                    </p>
             </div>
         </div>
     </div>
 </div>
 
 <hr>
 
 <div class="instructor-details-desc">
     <div class="row">
         <div class="col-lg-8 col-md-8">
             <div class="instructor-details">
                 <h3>MARKETING TEAM</h3>
                 <span class="sub-title">-----------------</span>
                 <p>This includes our online and offline marketing team. Our offline team helps in converting prospects into customers by show-casing appropriate primary residential as well as commercial properties to the prospects by matching the investment needs.<br/>
                    By using leads generated by Marketing Team to convert them into customers, through local activities and procuring relevant interested clients, doing on-ground activities in corporates.</p>
             </div>
         </div>
         <div class="col-lg-4 col-md-4">
             <div class="instructor-details-sidebar">
                 <img src="assets/images/career-dashboard2.jpeg" alt="image">
             </div>
         </div>
     </div>
 </div>
 
 <hr>
 
 <div class="instructor-details-desc">
     <div class="row">
         <div class="col-lg-4 col-md-4">
             <div class="instructor-details-sidebar">
                 <img src="assets/images/career-dashboard3.jpeg" alt="image">
             </div>
         </div>
         <div class="col-lg-8 col-md-8">
             <div class="instructor-details">
                 <h3>FINANCE TEAM</h3>
                 <span class="sub-title">-----------------</span>
                 <p>Our dedicated finance team takes care of the entire financial aspect. Ensuring timely receipt of brokerages and disbursement as well as honour of credit notes issued to clients. They also take care of the daily operational expenses. The Finance team helps in providing the market intelligence, data analytics and insights for the marketing team to launch the right promotional and customer communication initiatives. They connect and coordinate with all the major banks to process client's loans with ease.</p>
             </div>
         </div>
     </div>
 </div>
 
 <hr>
 
 <div class="instructor-details-desc">
     <div class="row">
         <div class="col-lg-8 col-md-8">
             <div class="instructor-details">
                 <h3>TECHNOLOGY TEAM</h3>
                 <span class="sub-title">-----------------</span>
                 <p>The technical team at Myshopdeal provides solutions for all the technological products. From building websites, web based applications, landing pages to promoting our products on social media they help us with all the important technicalities.<br/>
                    What we need is someone with strong attention to detail and superior organizational skills.<br/>
                    High level of responsibility, ownership and sense of urgency in getting things done. Ability to work in a fast-paced, results-oriented and team-oriented environment. Strong computer and software skills.</p>
             </div>
         </div>
         <div class="col-lg-4 col-md-4">
             <div class="instructor-details-sidebar">
                 <img src="assets/images/career-product.jpeg" alt="image">
             </div>
         </div>
     </div>
 </div>
 
 <hr>
 
 <div class="instructor-details-desc">
     <div class="row">
         <div class="col-lg-4 col-md-4">
             <div class="instructor-details-sidebar">
                 <img src="assets/images/career-dashboard5.jpeg" alt="image">
             </div>
         </div>
         <div class="col-lg-8 col-md-8">
             <div class="instructor-details">
                 <h3>DESIGN TEAM</h3>
                 <span class="sub-title">-----------------</span>
                 <p>Our designing team is the center of all creativity. From designing attractive brochures, flyers, standees, social media posts to branding for our clients they do everything. Our designing team brings fresh design perspective, innovative design ideas for the deliverables.<br/>
                    They are proficient and up-to-date with graphics software tools and equipment required for the position, including Photoshop, Illustrator, InDesign, Acrobat, and other latest creative softwares etc.They are efficient participants in planning, scheduling, and production of various designing requirements of our firm.</p>
             </div>
         </div>
     </div>
 </div>
 
 <hr>
 
 <div class="instructor-details-desc">
     <div class="row">
         <div class="col-lg-7 col-md-7">
             <div class="instructor-details">
                 <h3>HR TEAM</h3>
                 <span class="sub-title">-----------------</span>
                 <p>Our Hr team is involved with recruiting, training and motivating the team of Myshopdeal. Keeping the team together and establishing good relations with clients, builders and everyone. The Hr also takes up the responsibility of determining the pay roll, designing the company laws and procedures and making sure everyone follows them. Coordinate employee training and development initiatives.<br/>
                    The Hr team helps to manage internal communication projects as well as take job satisfaction and clients satisfaction surveys.</p>
             </div>
         </div>
         <div class="col-lg-5 col-md-5">
             <div class="instructor-details-sidebar">
                 <img src="assets/images/career-dashboard6.jpeg" alt="image">
             </div>
         </div>
     </div>
 </div>
 
 
 </div>
 </div>

 <div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>