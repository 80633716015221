<app-main-banner></app-main-banner>

<app-boxes></app-boxes>

<app-homefour-courses [propertySet]="propertySet" [fimgURL]="fimgURL" [fploading]="fploading"></app-homefour-courses>

<app-our-mission></app-our-mission>

<div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Myshopdeal Categories</span>
            <h2>Browse Our Categories</h2>
            <a routerLink="/all-categories" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>



<section class="faq-area bg-f8e8e9 pt-70 ptb-100">
    <app-faq></app-faq>
    <div class="divider bg-f9f9f9"></div>
</section>

<app-homeeight-events></app-homeeight-events>


<div class="testimonials-wrap-area bg-E7F0FD ptb-70">
    <app-happy-students-feedback></app-happy-students-feedback>
</div>

<div class="instructor-area ptb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Brands associated with Myshopdeal</h2>
            <p>Trumph, M3M, Paras, Sobha, Emaar, DLF, Godrej Properties, Hero Homes, Krisumi, Tata Housing, WTC, Whiteland etc are some of the A category builders whom we are associated with. We believe in giving our clients the best experience.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>

<app-why-choose-us></app-why-choose-us>

<div class="pb-100">
<app-offer></app-offer>
</div>

<div class="partner-area bg-color2 ptb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Corporate Clients</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Happy Moments</span>
            <h2>Our Happy Clients</h2>
            <a routerLink="/all-happy-clients" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div>

<div class="funfacts-style-two pt-100 pb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>


<app-banks-home></app-banks-home>


<div class="partner-area bg-color ptb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Myshopdeal Partners</h2>
        </div>
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>