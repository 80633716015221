<div class="instructor-slides">
    <owl-carousel-o [options]="instructorSlides">
        <ng-container *ngFor="let item of dataSet">
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img src="{{fimgURL+item.brand_image}}" alt="image">
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                    <div class="desc">
                        {{item.description}}
                    </div>
                </div>
                <div class="content">
                    <h3><a routerLink="/single-instructor">{{item.brand_owner}}</a></h3>
                    <span>{{item.brand_name}}</span>
                </div>
            </div>
        </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>