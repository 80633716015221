<div class="page-title-area page-title-style-two item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>All Testimonials</li>
            </ul>
            <h2>All Testimonials</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let item of dataSet; let i = index">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="{{fimgURL+item.author_image}}" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>{{item.author_name}}</h3>
                            <span>{{ item.author_designation }}</span>
                        </div>
                    </div>
                    <p>“{{ (item.testimonial.length>200)? (item.testimonial | slice:0:197)+'...':(item.testimonial) }}”</p>
                    <div class="review-stars-rated">
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>