<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area pt-70 pb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career"> Company</a></li>
                <li><a routerLink="/career-leadership"> Leadership</a></li>
                <li><a routerLink="/career-product-management" class="active"> Product Management</a></li>
                <li><a routerLink="/career-operation"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support"> Customer Support</a></li>
                <li><a routerLink="/career-joinus"> Join Us</a></li>
            </ul>
        </div>
    </div>
    </div>

<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">Product Manager</span>
                    <h3>MYSHOPDEAL</h3>
                    <h4>A NEW LANGUAGE OF FRIENDSHIP</h4>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Constantly work with business owners, sales, customer service and operation team to understand our mission and then collaborate with technology & design teams to move closer in achieving that mission.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Responsible for steering products throughout the innovation stage & execution cycle, focusing specifically on analyzing, tweaking and promoting your product to our intended customer base.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Create and execute product initiatives focused on driving measurable results in conversions, user engagement and traffic growth on Myshopdeal website and Apps.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Work with the team to plan and drive execution of consumer-facing features.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Taking care of the entire product line life cycle from understanding customers needs to planning to tactical activities. Finding and elaborating on the market requirements for current and future products by conducting market research supported and customer interaction.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Driving a solution set across Development and Marketing team.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Developing and implementing a company-wide go-to-market plan, working with all departments to execute.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Develop detailed, crisp requirements that can be used to create product specifications and architecture for each product.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Successful candidates will be detail driven, have a technical background and have excellent problem-solving abilities.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Should be able to mentor and groom high potential product managers.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/images/career-operation.jpeg" alt="image" style="border-radius:200px 0;">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="new-comers-area ptb-70">
    <div class="container">
        <hr>
    </div>
</div>        
<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 pb-100">
                <div class="new-comers-image">
                    <img src="assets/images/career-product.jpeg" alt="image" style="border-radius:0 200px;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <h3>APPLY IF YOU HAVE....</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    A technical undergraduate degree with some hands-on software engineering experience.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Streamlined thinking and Logical depth to break down complex problems in solvable steps.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Can take swift action and hunger for results to figure out what's best for your customer.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    High bar for design and product architecture - something you wish to exemplify in not only your contributions but also the products you work on.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Love for Data: Ability to make quick decisions based on small or large sets of data.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="new-comers-btn">
                        <a routerLink="/career-joinus" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Apply Now</span>
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>