<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
    <ng-container *ngFor="let item of dataSet">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="{{fimgURL+item.partner_image}}" target="_blank" class="d-block">
                    <img src="{{fimgURL+item.partner_image}}" alt="image">
                </a>
            </div>
        </ng-template>
    </ng-container>
    </owl-carousel-o>
</div>