<div class="page-title-areas">
    <div class="container-fluid">
        <div *ngIf="fploading"><img class="loaderImg" src="assets/images/circle.svg"></div>
        <owl-carousel-o [options]="categoriesSlides" *ngIf="!fploading">
            <ng-container *ngIf="propertySet?.propertyImages?.length > 0">
            <ng-template carouselSlide *ngFor="let img of propertySet?.propertyImages">
                <a href="{{fimgURL+'property/'+img.image}}" target="_blank">
                <img src="{{fimgURL+'property/'+img.image}}" alt="image" class="main_image" onerror="this.onerror=null;this.src='assets/images/no-image.jpeg';">
                </a>
            </ng-template>
           </ng-container>
           <ng-template carouselSlide *ngIF="!propertySet?.propertyImages || propertySet?.propertyImages?.length == 0">
            <a href="#" target="_blank">
            <img src="assets/images/no-image.jpeg" alt="image" class="main_image">
            </a>
        </ng-template>
        </owl-carousel-o>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container" *ngIf="propertySet">
        
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-9">
                    <div class="courses-title">
                        <h2>{{propertySet.name}}</h2>
                        <p>{{propertySet.description}}</p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Type</span>
                                <a routerLink="/">{{propertySet.wantTo}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Published By</span>
                                <a routerLink="/">{{propertySet.published_by}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Published At</span>
                                <a routerLink="/">{{propertySet.createdAt | date:'dd MMMM yyyy'}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="courses-price">
                        <!-- <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div> -->
                        <div class="price">{{propertySet.expectedPrice}}<span class="rental" *ngIf="propertySet.wantTo == 'rent'">/month</span></div>
                        <a routerLink="/" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">SCHEDULE A CALL</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-desc">
                    <h3>Configuration</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li *ngIf="propertySet.bedroom">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Bedroom: <span>{{propertySet.bedroom}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.bathroom">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Bathroom: <span>{{propertySet.bathroom}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.balcony">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Balcony: <span>{{propertySet.balcony}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.otherRooms">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Other Rooms: <span *ngFor="let orm of propertySet.otherRooms">{{orm}}, </span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.parking">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Parking: <span>{{propertySet.parking}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.totalFloor">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Total Floor: <span>{{propertySet.totalFloor}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.propertyFloor">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Property Floor: <span>{{propertySet.propertyFloor}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.staircase">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Staircase: <span>{{propertySet.staircase}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.lift">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Lift: <span>{{propertySet.lift}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.fasing">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Fasing: <span>{{propertySet.fasing}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.furnishing">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Furnishing: <span>{{propertySet.furnishing}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.constructionAny">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Construction Any: <span>{{propertySet.constructionAny}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.openSides">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Open Sides: <span>{{propertySet.openSides}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.locatedNear">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Located Near: <span>{{propertySet.locatedNear}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.parkingType">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Parking Type: <span>{{propertySet.parkingType}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.pantryType">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Pantry Type: <span>{{propertySet.pantryType}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.availability">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Availability: <span>{{propertySet.availability}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.propertyAge">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Property Age: <span>{{propertySet.propertyAge}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.possionBy">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Possion By: <span>{{propertySet.possionBy}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.fireSafety">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Fire Safety: <span>{{propertySet.fireSafety}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.reraApproved">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Rera Approved: <span>{{propertySet.reraApproved}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.qualityRating">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Quality Rating: <span>{{propertySet.qualityRating}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.securityLevel">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Security Level: <span>{{propertySet.securityLevel}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.businessType">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Business Type: <span>{{propertySet.businessType}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.seatsNo">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Seats No: <span>{{propertySet.seatsNo}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.cabinsNo">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Cabins No: <span>{{propertySet.cabinsNo}}</span>
                                </span>
                            </li>
                            <li *ngIf="propertySet.published_by">
                                <span>
                                    <i class='bx bx-check'></i>
                                    Published By: <span>{{propertySet.published_by}}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li *ngIf="propertySet.plotArea">
                            <span><i class='bx bxs-institution'></i> Plot Area:</span>
                            {{propertySet.plotArea}}
                        </li>
                        <li *ngIf="propertySet.carpetArea">
                            <span><i class='bx bxs-institution'></i> Carpet Area:</span>
                            {{propertySet.carpetArea}}
                        </li>
                        <li *ngIf="propertySet.buildUpArea">
                            <span><i class='bx bxs-institution'></i> Build-Up Area:</span>
                            {{propertySet.buildUpArea}}
                        </li>
                        <li *ngIf="propertySet.superBuildUpArea">
                            <span><i class='bx bxs-institution'></i> Super Build-Up:</span>
                            {{propertySet.superBuildUpArea}}
                        </li>
                        <li *ngIf="propertySet.lengthPlot">
                            <span><i class='bx bx-certification'></i> Plot Length:</span>
                            {{propertySet.lengthPlot}}
                        </li>
                        <li *ngIf="propertySet.breadthPlot">
                            <span><i class='bx bx-certification'></i> Plot Breadth:</span>
                            {{propertySet.breadthPlot}}
                        </li>
                        <li *ngIf="propertySet.roadWidth">
                            <span><i class='bx bx-atom'></i> Road Width:</span>
                            {{propertySet.roadWidth}}
                        </li>
                        <li *ngIf="propertySet.ceilingHeight">
                            <span><i class='bx bx-atom'></i> Ceiling Height:</span>
                            {{propertySet.ceilingHeight}}
                        </li>
                        <li *ngIf="propertySet.entranceWidth">
                            <span><i class='bx bx-atom'></i> Entrance Width:</span>
                            {{propertySet.entranceWidth}}
                        </li>
                    </ul>
                </div>
                <aside class="widget-area">
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Need Help ?</span>
                            <a href="tel:+919711750505">+91-97117 50505</a>
                        </div>
                    </div>
                    </aside>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-desc">
                    <h3>About Property</h3>
                    <p>{{propertySet.description}}</p>


                <hr>


                    <div id="propertydetails_feature" class="listing-caption" *ngIf="propertySet.amenities.length > 0">
                        <h3>Amenities</h3>
                        <ul class="list-inline feat">
                            <li *ngFor="let amn of propertySet.amenities"><img src="{{amn.image}}"
                                alt="wine cellar" title="wine cellar" class="img-responsive"> {{amn.name}}</li>                        
                        </ul>
                    </div>
                   
                  <ng-container  *ngIf="propertySet.facilities.length > 0">
                    <h3>Distance key between facilities</h3>
                    <ul class="list-facilities">
                        <li *ngFor="let fcp of propertySet.facilities">
                            <span class="facilities">
                                <img src="{{fcp.image}}"
                                    alt="wine cellar" title="wine cellar" class="img-responsive"> {{fcp.name}}
                            </span>
                            <span class="distance">{{fcp.description}}</span>
                        </li>                    
                    </ul>
                  </ng-container>

                  <ng-container  *ngIf="propertySet.videoUrl">
                    <h3>Project/Property Video</h3>
                  <div class="faq-video videoframe">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a
                            data-lg-size="1280-720"
                            data-sub-html="<p>Myshopdeal</p>"
                            href="{{propertySet.videoUrl}}"
                        >
                            <img src="{{fimgURL+'property/'+propertySet.propertyImages[0].image}}" alt="image">
                            <div class="video-btn">
                                <i class='bx bx-play'></i>
                            </div>
                        </a>
                    </lightgallery>
                </div>
                </ng-container>

                    <!-- <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/images/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">James Anderson</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>
                        <div class="user-review">
                            <img src="assets/images/user2.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>
                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>
                        <div class="user-review">
                            <img src="assets/images/user3.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">David Warner</span>
                            </div>
                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4">
                <div class="students-feedback-form">
                    <h3>Contact Us</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your name*">
                                    <span class="label-title"><i class='bx bx-user'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your address*">
                                    <span class="label-title"><i class='bx bx-home'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your email*">
                                    <span class="label-title"><i class='bx bx-envelope'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your phone*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Write something here (Optional)"></textarea>
                                    <span class="label-title"><i class='bx bx-edit'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      
    </div>
</div>

<div class="container-fluid">
    <div #map id="map"></div>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.8655319554505!2d77.2386164877095!3d28.543761225498123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3d25933fe69%3A0xb7de989325613ae9!2sB-11%2C%20Greater%20Kailash%20Enclave%20I%2C%20Greater%20Kailash%2C%20New%20Delhi%2C%20Delhi%20110048!5e0!3m2!1sen!2sin!4v1694690281749!5m2!1sen!2sin" style="border:0; width:100%; height:600px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
</div>