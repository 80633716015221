import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../../provider/service.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  bg_image: any = 'assets/images/blog_bg.jpeg';
  
  userDataPost={"userID":"", "o_companyID":1};
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit(): void {
    this.get_all_published_blogs();
  }

  fslotsData:any; blogsSet:any;  fimgURL:any; fploading:boolean = false;
  get_all_published_blogs(){
       this.fploading = true;
       this.service.postData(this.userDataPost, 'get_all_published_blogs').then((result) => {
         this.fslotsData = result;
         if (this.fslotsData.blogsData){
          this.fploading = false;
       this.blogsSet = this.fslotsData.blogsData;
       this.fimgURL = this.fslotsData.fimgURL;
         }else{
        this.fploading = false;
       this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
         }
      }, (err) => { 
      this.fploading = false;
      this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
      });
    } 


/* Alert Message */
show_alert:boolean = false; message:any;
alert(type, message){
  this.show_alert = true;
  this.message={'type':type,'text':message};
  setTimeout(() => {
    this.show_alert = false;
  }, 5000);
}
close_alert(){
  this.show_alert = false;
  this.message = '';
}
/* End of Alert Message */

}
