<div class="row">
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-group'></i>
            </div>
            <h3><span [countUp]="1305">00</span></h3>
            <p>APARTMENTS</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-book-reader'></i>
            </div>
            <h3><span [countUp]="1071">00</span></h3>
            <p>HOUSES</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-user-pin'></i>
            </div>
            <h3><span [countUp]="1494">00</span></h3>
            <p>COMMERCIAL</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxl-deviantart'></i>
            </div>
            <h3><span [countUp]="2502">00</span></h3>
            <p>LAND</p>
        </div>
    </div>
</div>