import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../provider/service.service';

@Component({
  selector: 'app-all-testimonials',
  templateUrl: './all-testimonials.component.html',
  styleUrls: ['./all-testimonials.component.scss']
})
export class AllTestimonialsComponent implements OnInit {

  bg_image: any = 'assets/images/blog_bg.jpeg';

  userDataPost={"userID":"", "o_companyID":1};
  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.get_all_testimonials();
  }

  fslotsData:any; dataSet:any;  fimgURL:any; loading:boolean = false;
  get_all_testimonials(){
       this.loading = true;
       this.service.postData(this.userDataPost, 'get_all_testimonials').then((result) => {
         this.fslotsData = result;
         if (this.fslotsData.testimonialsData){
          this.loading = false;
       this.dataSet = this.fslotsData.testimonialsData;
       this.fimgURL = this.fslotsData.fimgURL;
         }else{
        this.loading = false;
       this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
         }
      }, (err) => { 
      this.loading = false;
      this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
      });
    } 


/* Alert Message */
show_alert:boolean = false; message:any;
alert(type, message){
  this.show_alert = true;
  this.message={'type':type,'text':message};
  setTimeout(() => {
    this.show_alert = false;
  }, 5000);
}
close_alert(){
  this.show_alert = false;
  this.message = '';
}
/* End of Alert Message */

}
