<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-start">
                <h2>Our Values</h2>
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="values-content">
                <h3>A success-oriented learning environment</h3>
                <p>Website.com began in 2005. After years in the web hosting industry, we realized that it was near impossible for the average Jane or Joe to create their own website. Traditional web hosting services were simply too complicated, time consuming, and expensive to manage.</p>
                <h3>Academic Excellence and Cultural Diversity</h3>
                <p>We created the Website.com Site Builder with the user's perspective in mind. We wanted to offer a platform that would require no coding skills or design experience. We keep it simple, so users can focus on creating an amazing website that reflects their brand. Best of all - it's free. You can get online, showcase your brand, or start selling products right away.</p>
                <h3>Advancing human understanding</h3>
                <p>After seeing an increased need for eCommerce solutions, we developed one of the only fully-featured, free and commission-free online store builders, allowing business owners to launch their online business.</p>
            </div>
        </div>
    </div>
</div>