<div class="boxes-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>HOME LOAN ASSITANCE</h3>
                    <p>We assist you with home loans and registration because we believe that buying a home is one of the most important investments. so it should be the safest and best deal for you.</p>
                    <!-- <a routerLink="/courses-2-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-image">
                    <h3>SITE VISIT</h3>
                    <p>We help in refining your wish list into a realistic ‘buyer’s brief’, preparing a shortlist of suitable properties,so that you can visit the sites which are just right for you and not go through the hassle.</p>
                    <!-- <a routerLink="/courses-2-columns-style-2" class="boxes-btn">View More<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>UNIT & BOOKING</h3>
                    <p>If there is this particular unit you have fallen in love with but you want to discuss it with your family and friends but at the same time you don''t want to let it slip by.</p>
                    <!-- <a routerLink="/courses-3-columns-style-1" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>AFTER SALES SERVICES</h3>
                    <p>Our services to you continues even after the registry of the flat is over.we believe in long term and permanent relationship building. we believe in friendship.</p>
                    <!-- <a routerLink="/courses-4-columns-style-1" class="boxes-btn">View More<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>