import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../provider/service.service';

@Component({
  selector: 'app-homeeight-events',
  templateUrl: './homeeight-events.component.html',
  styleUrls: ['./homeeight-events.component.scss']
})
export class HomeeightEventsComponent implements OnInit {

  userDataPost={"userID":"", "o_companyID":1};
  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.get_all_published_blogs_limit();
  }

  fslotsData:any; dataSet:any;  fimgURL:any; loading:boolean = false;
  get_all_published_blogs_limit(){
       this.loading = true;
       this.service.postData(this.userDataPost, 'get_all_published_blogs_limit').then((result) => {
         this.fslotsData = result;
         if (this.fslotsData.blogsData){
          this.loading = false;
       this.dataSet = this.fslotsData.blogsData;
       this.fimgURL = this.fslotsData.fimgURL;
         }else{
        this.loading = false;
       this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
         }
      }, (err) => { 
      this.loading = false;
      this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
      });
    } 


/* Alert Message */
show_alert:boolean = false; message:any;
alert(type, message){
  this.show_alert = true;
  this.message={'type':type,'text':message};
  setTimeout(() => {
    this.show_alert = false;
  }, 5000);
}
close_alert(){
  this.show_alert = false;
  this.message = '';
}
/* End of Alert Message */

}
