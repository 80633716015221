<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="section-title text-start">
                <h2>Core Strength<br> & <br>Belief</h2>
            </div>
        </div>
        <div class="col-lg-8 col-md-8">
            <div class="story-content">
                <h3>1. Customer Centricity - </h3>
                <p>From starting point of making a decision about buying a home to loan sanction and after sale relationship management, settling into the new house, home decor,smart security setup etc, we are there in all the junctures. </p>
                <h3>2. Authenticity -</h3>
                <p>Every information provided by us, our website, social media pages, blogs contain authentic details which are directly provided and communicated by the builders themselves. These are up to date and genuine.</p>
                <h3>3. Only A Class Builder -</h3>
                <p>We know the pain associated with B category builders, thats why we have associated ourselves with only A class builders and developers like Godrej, DLF, Emaar, Vatika, Bptp, particularly in mid to luxury segment.</p>
        </div>  
        </div>
        <div class="col-lg-12 col-md-12 pt-70">
            <div class="story-content">      
                <h3>4. Modern Technology -</h3>
                <p>Using latest Digital Marketing technology we resolve customer queries within 24 hours. We respond to client queries using tickets system, giving priority to the ones who need immediate attention.</p>
                <h3>5. Transparency -</h3>
                <p>Even if we are modern in our approach our belief system is traditional. We have ethics, values and moral as our core strengths. We want to redefine new standards and set the benchmark for honesty and transparency in the real estate industry.</p>
                <h3>6. Trustability -</h3>
                <p>Since we work on trust and transparency our clients are extremely happy and you will be surprised to know that most of our transactions have been through referrals of our existing happy customers.</p>
                <h3>7. Global Connectivity -</h3>
                <p>We are humbled by the fact that most of our clients come not only from India but all over the globe. Many of our happy clients are based out in USA, Dubai and Singapore and are NRIs and HNIs. We can proudly say we are globally connected.</p>
                <h3>8. Our Tag Line -</h3>
                <p>A lot of thought has been put into out tag line : U Demand V Deliver. We have been in this industry for a long time and have seen how it works. We give in our best to deliver not only products but a complete experience.</p>
            </div>
        </div>
    </div>
</div>