<div class="page-title-area page-title-style-two item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>All Categories</li>
            </ul>
            <h2>All Categories</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let item of dataSet; let i = index">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-layer'></i>
                    </div>
                    <h3>{{ (item.category.length>22)? (item.category | slice:0:19)+'...':(item.category) }}</h3>
                    <span>{{item.p_category? item.p_category : '--'}}</span>
                    <a routerLink="/property/{{item.slug}}" class="link-btn"></a>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>