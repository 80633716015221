import { Component } from '@angular/core';

@Component({
  selector: 'app-career-operation',
  templateUrl: './career-operation.component.html',
  styleUrls: ['./career-operation.component.scss']
})
export class CareerOperationComponent {

}
