import { Component } from '@angular/core';

@Component({
  selector: 'app-career-joinus',
  templateUrl: './career-joinus.component.html',
  styleUrls: ['./career-joinus.component.scss']
})
export class CareerJoinusComponent {

}
