import { Component } from '@angular/core';

@Component({
  selector: 'app-career-company',
  templateUrl: './career-company.component.html',
  styleUrls: ['./career-company.component.scss']
})
export class CareerCompanyComponent {

}
