import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../provider/service.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-banks-home',
  templateUrl: './banks-home.component.html',
  styleUrls: ['./banks-home.component.scss']
})
export class BanksHomeComponent {

	partnerSlides: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 4
			},
			576: {
				items: 5
			},
			768: {
				items: 7
			},
			1200: {
				items: 10
			}
		}
    }

  userDataPost={"userID":"", "o_companyID":1};
	constructor(public service: ServiceService) { }
  
	ngOnInit(): void {
	  this.get_all_banks();
	}
  
	fslotsData:any; dataSet:any;  fimgURL:any; loading:boolean = false;
	get_all_banks(){
		 this.loading = true;
		 this.service.postData(this.userDataPost, 'get_all_banks').then((result) => {
		   this.fslotsData = result;
		   if (this.fslotsData.bankData){
			this.loading = false;
		 this.dataSet = this.fslotsData.bankData;
		 this.fimgURL = this.fslotsData.fimgURL;
		   }else{
		  this.loading = false;
		 this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
		   }
		}, (err) => { 
		this.loading = false;
		this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
		});
	  } 

      /* Alert Message */
  show_alert:boolean = false; message:any;
  alert(type, message){
	this.show_alert = true;
	this.message={'type':type,'text':message};
	setTimeout(() => {
	  this.show_alert = false;
	}, 5000);
  }
  close_alert(){
	this.show_alert = false;
	this.message = '';
  }
  /* End of Alert Message */

  }