<section class="why-choose-us-area">
<div class="container-fluid">
    <div class="section-title">
        <h2>Banks For Home Loan</h2>
    </div>
<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
    <ng-container *ngFor="let item of dataSet">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <img src="{{fimgURL+item.bank_logo}}" alt="image">
            </div>
        </ng-template>
    </ng-container>
    </owl-carousel-o>
</div>
<p>&nbsp;</p>
</div>
<div class="lang-shape2"><img src="assets/images/lang-shape2.png" alt="image"></div>
</section>