<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
      <ng-container *ngFor="let item of dataSet; let i = index">
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30" [ngClass]="{'bg-box': i && animate}" [attr.data-order]="animate ? i : null">
                <div class="icon">
                    <i class='bx bx-layer'></i>
                </div>
                <h3>{{ (item.category.length>22)? (item.category | slice:0:19)+'...':(item.category) }}</h3>
                <span>{{item.p_category? item.p_category : '--'}}</span>
                <a routerLink="/property/{{item.slug}}" class="link-btn"></a>
            </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
</div>