<div class="page-title-area page-title-style-two item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blogs</li>
            </ul>
            <h2>Blogs</h2>
        </div>
    </div>
</div>

<section class="events-area bg-f9f9f9 pt-100 pb-70">
    <div class="col-md-12" *ngIf="fploading"><img class="loaderImg" src="assets/images/circle.svg"></div>
    <div class="container" *ngIf="!fploading">
        <div class="row">
            <ng-container *ngFor="let item of blogsSet; let i = index">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <a [routerLink]="'/blog-details/'+item.blogId">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image">
                                <img src="{{fimgURL+item.image}}" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <h3>{{ (item.title.length>60)? (item.title | slice:0:57)+'...':(item.title) }}</h3>
                                <p>{{ (item.short_description.length>100)? (item.short_description | slice:0:97)+'...':(item.short_description) }}</p>
                                <span class="location"><i class="bx bx-calendar"></i>{{item.postAt}}</span>
                            </div>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
            </ng-container>
        </div>
    </div>

</section>