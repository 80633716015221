import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ServiceService } from '../../provider/service.service';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    blogSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			1200: {
				items: 3
			}
		}
    }

	userDataPost={"userID":"", "o_companyID":1};
	constructor(public service: ServiceService) { }
  
	ngOnInit(): void {
	  this.get_all_clients();
	}
  
	fslotsData:any; dataSet:any;  fimgURL:any; loading:boolean = false;
	get_all_clients(){
		 this.loading = true;
		 this.service.postData(this.userDataPost, 'get_all_clients').then((result) => {
		   this.fslotsData = result;
		   if (this.fslotsData.clientsData){
			this.loading = false;
		 this.dataSet = this.fslotsData.clientsData;
		 this.fimgURL = this.fslotsData.fimgURL;
		   }else{
		  this.loading = false;
		 this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
		   }
		}, (err) => { 
		this.loading = false;
		this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
		});
	  } 
  
  
  /* Alert Message */
  show_alert:boolean = false; message:any;
  alert(type, message){
	this.show_alert = true;
	this.message={'type':type,'text':message};
	setTimeout(() => {
	  this.show_alert = false;
	}, 5000);
  }
  close_alert(){
	this.show_alert = false;
	this.message = '';
  }
  /* End of Alert Message */

}