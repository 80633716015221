<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Projects</li>
            </ul>
            <h2 class="slug">{{userDataPost.categorySlug}}</h2>
        </div>
    </div>
</div>

<div class="courses-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 left-bar">
                <aside class="widget-area">
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/residential-property/residential">Residential</a></li>
                            <li><a routerLink="/commercial-property/commercial">Commercial</a></li>
                        </ul>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">No. of Bedrooms</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">1 RK</a>
                            <a routerLink="/residential-property/residential">1 BHK</a>
                            <a routerLink="/residential-property/residential">2 BHK</a>
                            <a routerLink="/residential-property/residential">3 BHK</a>
                            <a routerLink="/residential-property/residential">4 BHK</a>
                            <a routerLink="/residential-property/residential">5 BHK</a>
                            <a routerLink="/residential-property/residential">5+ BHK</a>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">No. of bathrooms</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">1</a>
                            <a routerLink="/residential-property/residential">2</a>
                            <a routerLink="/residential-property/residential">3</a>
                            <a routerLink="/residential-property/residential">4</a>
                            <a routerLink="/residential-property/residential">5</a>
                            <a routerLink="/residential-property/residential">5+</a>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Available for</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">Family</a>
                            <a routerLink="/residential-property/residential">Single Men</a>
                            <a routerLink="/residential-property/residential">Single Women</a>
                            <a routerLink="/residential-property/residential">Tenants with Company Lease</a>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Posted by</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">Owner</a>
                            <a routerLink="/residential-property/residential">Builder</a>
                            <a routerLink="/residential-property/residential">Dealer</a>
                            <a routerLink="/residential-property/residential">Feature Dealer</a>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Furnishing status</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">Furnished</a>
                            <a routerLink="/residential-property/residential">Semifurnished</a>
                            <a routerLink="/residential-property/residential">Unfurnished</a>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Amenities</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">Lift</a>
                            <a routerLink="/residential-property/residential">Parking</a>
                            <a routerLink="/residential-property/residential">Power Backup</a>
                            <a routerLink="/residential-property/residential">Park</a>
                            <a routerLink="/residential-property/residential">Gas Pipeline</a>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Availability Status</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential">Ready to move</a>
                            <a routerLink="/residential-property/residential">Under construction</a>
                        </div>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Possion By</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential" *ngFor="let ps of expectedBy">{{ps}}</a>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Facing</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential" *ngFor="let fc of facings">{{fc}}</a>
                        </div>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Age of Property</h3>
                        <div class="tagcloud">
                            <a routerLink="/residential-property/residential" *ngFor="let age of ageProperty">{{age}}</a>
                        </div>
                    </div>

                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Need Help ?</span>
                            <a href="tel:+919711750505">+91-9711750505</a>
                        </div>
                    </div>
                </aside>
            </div>

            <div class="col-lg-9 col-md-9">
                <div class="courses-topbar">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="topbar-ordering-and-search">
                                <div class="row align-items-center">
                                    <div class="col-lg-7 col-md-7">
                                        <div class="topbar-search">
                                            <form>
                                                <label><i class="bx bx-search"></i></label>
                                                <input type="text" class="input-search" placeholder="Search here...">
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 offset-lg-2 offset-md-2">
                                        <div class="topbar-ordering">
                                            <select class="form-select">
                                                <option>Sort by popularity</option>
                                                <option>Sort by latest</option>
                                                <option>Default sorting</option>
                                                <option>Sort by rating</option>
                                                <option>Sort by new</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" *ngIf="fploading"><img class="loaderImg" src="assets/images/circle.svg"></div>
                    <ng-container  *ngIf="!fploading">
                    <div class="col-lg-12 col-md-12" *ngFor="let item of propertySet">
                        <div class="single-courses-list-box mb-30">
                            <div class="box-item" (click)="openPropertyDetails(item.slug)">
                                <div class="courses-image">
                                    <div class="type-tag">{{item.wantTo}}</div>
                                    <owl-carousel-o [options]="missionSlides">
                                        <ng-container  *ngIf="item?.propertyImages?.length > 0">
                                            <ng-template carouselSlide *ngFor="let img of item?.propertyImages">
                                                <img src="{{fimgURL+img.image}}" alt="Images" onerror="this.onerror=null;this.src='assets/images/no-image.jpeg';">
                                            </ng-template>
                                        </ng-container>

                                        <ng-template carouselSlide *ngIf="!item?.propertyImages || item?.propertyImages?.length == 0">
                                            <img src="assets/images/no-image.jpeg" alt="image">
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                                <div class="courses-desc">
                                    <div class="courses-content">
                                        <h3><a (click)="openPropertyDetails(item.slug)" class="d-inline-block">{{item.name}}</a></h3>
                                        <p *ngIf="item.description">
                                        {{((item.description.length)>100)?((item.description) | slice:0:96)+'...':(item.description) }}
                                        </p>
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-6"><h3 class="pricetag">Rs {{item.expectedPrice}}</h3><span class="rental" *ngIf="item.wantTo == 'rent'">/ Month</span></div>
                                            <div class="col-md-6"><h3 class="cp-area">{{item.carpetArea}} </h3><span class="rental">(Carpet Area)</span></div>
                                        </div>
                                        <hr>
                                        <p><span class="places"><i class='bx bx-map'></i> {{item.facilitiesCount}} Places nearby</span> 
                                            <span class="places-tag" *ngFor="let fac of item.facilities">{{fac.name}}</span>
                                        </p>
                                    </div>
                                    <div class="courses-box-footer">
                                        <ul>
                                            <li class="students-number">
                                                <img src="assets/images/property/icons/bed.svg" alt="Bedroom" title="Bedroom"> {{item.bedroom}}
                                            </li>
                                            <li class="courses-lesson">
                                                <img src="assets/images/property/icons/bath.svg" alt="Bathroom" title="Bathroom"> {{item.bathroom}}
                                            </li>
                                            <li class="courses-price">
                                                <img src="assets/images/property/icons/area.svg" alt="Balcony" title="Balcony"> {{item.balcony}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="courses-content">
                                        <p><i class='bx bx-map'></i> {{item.locality}}</p>
                                        <hr>
                                        <p><span class="facility-tag" *ngFor="let amn of item.amenities">{{amn.name}}</span>
                                        <hr *ngIf="item.furnishing || item.fasing">  
                                        <div class="row" *ngIf="item.furnishing || item.fasing">
                                            <div class="col-md-6" *ngIf="item.furnishing"><h3 class="cp-area">{{item.furnishing}}</h3> <span class="rental">(Furnishing)</span></div>
                                            <div class="col-md-6" *ngIf="item.fasing"><h3 class="cp-area">{{item.fasing}}</h3> <span class="rental">(Facing)</span></div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/courses-list" class="page-numbers">2</a>
                            <a routerLink="/courses-list" class="page-numbers">3</a>
                            <a routerLink="/courses-list" class="page-numbers">4</a>
                            <a routerLink="/courses-list" class="page-numbers">5</a>
                            <a routerLink="/courses-list" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
</div>