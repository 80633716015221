<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area pt-70 pb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career"> Company</a></li>
                <li><a routerLink="/career-leadership"> Leadership</a></li>
                <li><a routerLink="/career-product-management"> Product Management</a></li>
                <li><a routerLink="/career-operation" class="active"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support"> Customer Support</a></li>
                <li><a routerLink="/career-joinus"> Join Us</a></li>
            </ul>
        </div>
    </div>
    </div>

<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/images/career-operation.jpeg" alt="image" style="border-radius:0 200px;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">What does the role hold for you?</span>
                    <h3>MYSHOPDEAL</h3>
                    <h4>A NEW LANGUAGE OF FRIENDSHIP</h4>
                    <p>Employee Database, HRIS and employee related data activities.The individual in this role will be responsible for managing employee life cycle activities for all employees at PAN India level. These activities are :-</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Maintaining and reviewing accurate, error free database with all new joinees/resigners updations along with other updates and changes as per transfers/promotion etc in the excel format.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    On time & error free processing of letters, completion of pre and post joining formalities along with proper on time documentation of files.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Maintaining the data on time with accuracy.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Payroll & Exit Management.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Producing & providing error free & timely data every month for payroll input.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Coordination with relevant stakeholders for data gathering & ensuring on time collection of validated payroll input (attendance, deductions, etc) for capturing them in the inputs.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Execution of full & final settlement for resigned employees on time and with 100% accuracy.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Coordination with the Finance team & bank for on time & accurate payout to the employee (monthly).
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    On time and accurate generation of PIP letters, Termination letters, relieving letters, transfer letter, etc. and other ad hoc letters related to employees.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    MIS Reports.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Providing error free and accurate reports (headcount reports etc.).
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="new-comers-area ptb-70">
    <div class="container">
        <hr>
    </div>
</div>        
<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <h3>APPLY IF YOU HAVE....</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    4-7 years of experience in working in HR operations team, managing employee database, payroll, reimbursements and retirals.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Good verbal and written communication skills.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Good command over MS products including MS Excel, MS Word and MS Powerpoint.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Ability to manage multiple project independently.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Eye for detail and result oriented.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="new-comers-btn">
                        <a routerLink="/career-joinus" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Apply Now</span>
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pb-100">
                <div class="new-comers-image">
                    <img src="assets/images/career-operation2.jpeg" alt="image" style="border-radius:200px 0;">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>