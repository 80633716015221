<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area pt-70 pb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career"> Company</a></li>
                <li><a routerLink="/career-leadership"> Leadership</a></li>
                <li><a routerLink="/career-product-management"> Product Management</a></li>
                <li><a routerLink="/career-operation"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support"> Customer Support</a></li>
                <li><a routerLink="/career-joinus" class="active"> Join Us</a></li>
            </ul>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">Join Us</span>
                    <h2>MYSHOPDEAL</h2>
                    <h6>A NEW LANGUAGE OF FRIENDSHIP</h6>
                    <p>We are a team of enthusiasts and professionals who believe that you can perform your best if you can merge creativity, fun and work together. The client is our priority and be it night or day we are there to solve their problem. If you have the passion and fire within you to exceed beyond the ordinary, then Myshopdeal is the place for you!</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/images/joinus.jpeg" alt="image">
                    <img src="assets/images/joinus1.png" alt="image">
                    <div class="text-box">
                        <div class="inner">
                            Trusted By
                            <span>75K+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid paddingZero">
    <app-students-feedback-form></app-students-feedback-form>
</div>

<div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>