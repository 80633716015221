import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceService } from './provider/service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CountUpModule } from 'ngx-countup';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { HeaderComponent } from './shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { MainBannerComponent } from './pages/home/main-banner/main-banner.component';
import { OurMissionComponent } from './component/our-mission/our-mission.component';
import { BoxesComponent } from './component/boxes/boxes.component';
import { CategoriesStyleOneComponent } from './component/categories-style-one/categories-style-one.component';
import { FunfactsComponent } from './component/funfacts/funfacts.component';
import { PartnerStyleOneComponent } from './component/partner-style-one/partner-style-one.component';
import { InstructorsStyleOneComponent } from './component/instructors-style-one/instructors-style-one.component';
import { BlogComponent } from './component/blog/blog.component';
import { PartnerStyleTwoComponent } from './component/partner-style-two/partner-style-two.component';
import { OfferComponent } from './component/offer/offer.component';
import { HomefourCoursesComponent } from './component/homefour-courses/homefour-courses.component';
import { HomeeightEventsComponent } from './component/homeeight-events/homeeight-events.component';
import { FaqComponent } from './component/faq/faq.component';
import { WhyChooseUsComponent } from './component/why-choose-us/why-choose-us.component';
import { HappyStudentsFeedbackComponent } from './component/happy-students-feedback/happy-students-feedback.component';
import { BanksComponent } from './component/banks/banks.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BlogDetailsComponent } from './pages/blogs/blog-details/blog-details.component';
import { BanksHomeComponent } from './component/banks-home/banks-home.component';
import { PropertyComponent } from './pages/property/property.component';
import { PropertyDetailsComponent } from './pages/property-details/property-details.component';
import { BecomeInstructorPartnerComponent } from './component/become-instructor-partner/become-instructor-partner.component';
import { FeedbackStyleTwoComponent } from './component/feedback-style-two/feedback-style-two.component';
import { OurStoryComponent } from './component/our-story/our-story.component';
import { OurValuesComponent } from './component/our-values/our-values.component';
import { CareerComponent } from './pages/career/career.component';
import { CareerCompanyComponent } from './pages/career/career-company/career-company.component';
import { CareerLeadershipComponent } from './pages/career/career-leadership/career-leadership.component';
import { CareerProductComponent } from './pages/career/career-product/career-product.component';
import { CareerOperationComponent } from './pages/career/career-operation/career-operation.component';
import { CareerMarketingComponent } from './pages/career/career-marketing/career-marketing.component';
import { CareerCustomerComponent } from './pages/career/career-customer/career-customer.component';
import { CareerJoinusComponent } from './pages/career/career-joinus/career-joinus.component';
import { HomeloanComponent } from './pages/homeloan/homeloan.component';
import { StudentsFeedbackFormComponent } from './component/students-feedback-form/students-feedback-form.component';
import { FeedbackStyleOneComponent } from './component/feedback-style-one/feedback-style-one.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { AllCategoryComponent } from './pages/all-category/all-category.component';
import { AllTestimonialsComponent } from './pages/all-testimonials/all-testimonials.component';
import { AllHappyClientsComponent } from './pages/all-happy-clients/all-happy-clients.component';
import { AllBrandsComponent } from './pages/all-brands/all-brands.component';

@NgModule({
  declarations: [
    AppComponent,

    CategoriesStyleOneComponent,
    FunfactsComponent,
    PartnerStyleOneComponent,
    InstructorsStyleOneComponent,
    BlogComponent,
    OurMissionComponent,
    PartnerStyleTwoComponent,
    OfferComponent,
    HomefourCoursesComponent,
    BoxesComponent,
    HomeeightEventsComponent,
    FaqComponent,
    WhyChooseUsComponent,
    HappyStudentsFeedbackComponent,
    FeedbackStyleOneComponent,

    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    BlogsComponent,
    MainBannerComponent,
    BanksComponent,
    ContactComponent,
    BlogDetailsComponent,
    BanksHomeComponent,
    PropertyComponent,
    PropertyDetailsComponent,
    CareerComponent,
    CareerCompanyComponent,
    CareerLeadershipComponent,
    CareerProductComponent,
    CareerOperationComponent,
    CareerMarketingComponent,
    CareerCustomerComponent,
    CareerJoinusComponent,
    BecomeInstructorPartnerComponent,
    FeedbackStyleTwoComponent,
    OurStoryComponent,
    OurValuesComponent,
    HomeloanComponent,
    StudentsFeedbackFormComponent,
    LoginPageComponent,
    RegisterPageComponent,
    AllCategoryComponent,
    AllTestimonialsComponent,
    AllHappyClientsComponent,
    AllBrandsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    NgxScrollTopModule,
    LightboxModule,
    FormsModule,
    LightgalleryModule,
    HttpClientModule,
  ],
  providers: [ServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
