<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Projects</span>
            <h2>Our Latest Projects</h2>
            <!-- <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Projects</span><i class="bx bx-show-alt icon-arrow after"></i></a> -->
        </div>
        <p style="text-align:center;" *ngIf="fploading">Fetching Data ...</p>
        <div class="courses-slides" *ngIf="!fploading">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide  *ngFor="let item of propertySet">
                    <div class="single-courses-box without-box-shadow mb-30">
                        <div class="courses-image">
                            <a routerLink="/property-details/{{item.slug}}" class="d-block">
                                <img src="{{fimgURL+item?.propertyImages[0]?.image}}" alt="image" *ngIf="item?.propertyImages?.length > 0" onerror="this.onerror=null;this.src='assets/images/no-image.jpeg';">
                                <img src="assets/images/no-image.jpeg" alt="image" *ngIf="!item?.propertyImages || item?.propertyImages?.length == 0">
                            </a>
                            <div class="courses-tag">
                                <a routerLink="/property-details/{{item.slug}}" class="d-block">{{item.wantTo}}</a>
                            </div>
                        </div>
                        <div class="courses-content">
                            <!-- <div class="course-author d-flex align-items-center">
                                <img src="assets/images/user1.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Steven Smith</span>
                            </div> -->
                            <h3><a routerLink="/property-details/{{item.slug}}" class="d-inline-block">{{item.name}}</a></h3>
                            <p *ngIf="item.description">
                                {{((item.description.length)>100)?((item.description) | slice:0:96)+'...':(item.description) }}
                                </p>
                        </div>
                        <div class="courses-box-footer">
                            <p>{{item.locality}}</p>
                            <ul>
                                <!-- <li class="students-number">
                                    <i class='bx bx-user'></i> {{item.locality}}
                                </li> -->
                                <!-- <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> 6 lessons
                                </li> -->
                                <!-- <li class="courses-price">
                                    Rs {{item.expectedPrice}}<span class="rental" *ngIf="item.wantTo == 'rent'">/ Month</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>