import { Component, OnInit } from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

    settings = {
        counter: false,
        plugins: [lgVideo]
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    accordionItems = [
        {
            title: 'What are the benefits of using a real estate firm like yours?',
            content: 'Myshopdeal outlines the unique advantages and services offered by various properties and help clients make an informed choice.',
            open: false
        },
        {
            title: 'Can Myshopdeal guide me to buy property as an investment?',
            content: 'Myshopdeal guides every client the possibilities and potential advantages of purchasing real estate for investment purposes, such as land, residential and commercial properties as well as shop spaces.',
            open: false
        },
        {
            title: 'What happens if a property I want to buy has issues or defects?',
            content: 'Explains the process for negotiating repairs, credits, or price adjustments when issues are discovered during inspections.',
            open: false
        },
        {
            title: 'What should I do if I am selling my current home while buying a new one?',
            content: 'Myshopdeal offers advice on coordinating the sale of an existing property with the purchase of a new one, including contingency clauses and timing.',
            open: false
        },
        {
            title: 'How long does the home-buying process take and how will Myshopdeal help?',
            content: 'Myshopdeal addresses every question you have in mind and helps in the timeline involved in buying or selling a property, from the initial search to closing.',
            open: false
        }
    ];

    selectedItem = null;

    toggleAccordionItem(item) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}