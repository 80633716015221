<div class="blog-slides">
    <owl-carousel-o [options]="blogSlides">
        <ng-container *ngFor="let item of dataSet">
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/" class="d-block">
                        <img src="{{fimgURL+item.client_image}}" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <ul class="post-meta">
                        <li class="post-author">
                            <!-- <img src="assets/images/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image"> -->
                            <a routerLink="/" class="d-inline-block">{{item.client_name}}</a>
                        </li>
                        <li>{{item.property_name}}</li>
                    </ul>
                </div>
            </div>
        </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>