import { Component } from '@angular/core';

@Component({
  selector: 'app-homeloan',
  templateUrl: './homeloan.component.html',
  styleUrls: ['./homeloan.component.scss']
})
export class HomeloanComponent {
  bg_image: any = 'assets/images/homeloan-bg.jpeg';

}
