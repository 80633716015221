<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Career</li>
            </ul>
            <h2>Career</h2>
        </div>
    </div>
</div>

<div class="my-dashboard-area pt-70 pb-100">
    <div class="container">
        <div class="myDashboard-navigation">
            <ul>
                <li><a routerLink="/career"> Company</a></li>
                <li><a routerLink="/career-leadership"> Leadership</a></li>
                <li><a routerLink="/career-product-management"> Product Management</a></li>
                <li><a routerLink="/career-operation"> Operation</a></li>
                <li><a routerLink="/career-marketing-strategy" class="active"> Making Strategy</a></li>
                <li><a routerLink="/career-customer-support"> Customer Support</a></li>
                <li><a routerLink="/career-joinus"> Join Us</a></li>
            </ul>
        </div>
    </div>
    </div>

<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/images/career-marketing1.jpeg" alt="image" style="border-radius:0 200px;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">Marketing Strategy Manager</span>
                    <h3>MYSHOPDEAL</h3>
                    <h4>A NEW LANGUAGE OF FRIENDSHIP</h4>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Monitoring, Tracking and optimizing campaigns to ensure business deliveries.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Manage Housing's paid Facebook campaigns for real estate business.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Drive the implementation of new scalable, cost-efficient campaigns and maximize ROI against key metrics (Traffic, Lead, Conversion, Reach, Brand Awareness) for both Housing Site/App.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Ideate and implement Growth Initiatives while working with Marketing Tech Team to help drive platform conversion or Traffic.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Manage internal, agency and external relationships to run campaigns across a wide array of networks and media.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Develop strong internal relationships with our product management and technical establishments to drive customer experience and marketing improvements on Myshopdeal site.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="new-comers-area ptb-70">
    <div class="container">
        <hr>
    </div>
</div>        
<div class="new-comers-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <h3>APPLY IF YOU HAVE....</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="new-comers-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    A B.Tech/ MBA (will be an added advantage) degree with 2+ years experience of direct marketing work experience.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Deep knowledge of Facebook advertising ecosystem with hands-on experience of Facebook Business Manager.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Working knowledge of Excel.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Ability to work at the strategic level (generating bold and innovative ideas for growth) and at the tactical level (managing advertising campaign execution, creating and optimizing campaigns, analyzing traffic data and solving problems).
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Proven experience in traffic generation, managing online advertising and search on behalf of an internet business in B2C space.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Good analytical skills, with a sound grasp of performance optimization strategies.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Must be very commercially aware, having owned marketing budgets and revenue goals of significant scale.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Passion for metrics and quantitative analysis, and ability to turn analysis into actionable programs.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Be able to bridge data analysis, research, competitive intelligence and marketing savvy to build efficient and scalable customer acquisition programs.
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Strong drive for execution and delivering results. An ambitious self-starter with excellent follow through skills to develop ideas independently and thrive in a fast-paced environment is crucial.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="new-comers-btn">
                        <a routerLink="/career-joinus" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                            <span class="label">Apply Now</span>
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pb-100">
                <div class="new-comers-image">
                    <img src="assets/images/free-trial.png" alt="image"  style="float:right;">
                </div>
            </div>
            <p>&nbsp;</p>
        </div>
    </div>
</div>

<div class="partner-area bg-color ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>