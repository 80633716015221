<div class="page-title-area item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
                <li>Blog Details</li>
            </ul>
            <h2>{{dataSet.title}}</h2>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{fimgURL+dataSet.image}}" alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a href="#">{{dataSet.categoryId}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Author</span>
                                    <a href="#">{{dataSet.author}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Posted At</span>
                                    <a href="#">{{dataSet.postAt}}</a>
                                </li>        
                            </ul>
                        </div>
                        <h3>{{dataSet.title}}</h3>
                        <div [innerHTML]="htmlContent"></div>
                    </div>
                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            <a href="#">{{dataSet.categoryId}}</a>
                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item" *ngFor="let item of blogsSet">
                            <a [routerLink]="'/blog-details/'+item.blogId">
                            <div class="info">
                                <time datetime="2019-06-30">{{item.postAt}}</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">{{item.title}}</a></h4>
                            </div>
                            </a>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/residential-property/residential">Residential <span class="post-count">(36)</span></a></li>
                            <li><a routerLink="/commercial-property/commercial/">Commercial <span class="post-count">(27)</span></a></li>
                        </ul>
                    </div>
                    <!-- <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ednuv Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-style-2">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Raque <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div> -->
                    <!-- <div class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>
                        <ul>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/images/blog/blog1.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/images/blog/blog2.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/images/blog/blog3.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/images/blog/blog4.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/images/blog/blog5.jpg" alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/images/blog/blog6.jpg" alt="image"></a></li>
                        </ul>
                    </div> -->
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Need Help ?</span>
                            <a href="tel:+919711750505">+91-9711750505</a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>