<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-5">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">Myshopdeal, 7th floor, WeWork, HQ27 The Headquarters, B660, Sushant Loke Phase I, Sector-27, Gurugram, Haryana - 122009</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+919711750505">+91 9711750505</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:support@myshopdeal.co.in">support@myshopdeal.co.in</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/myshopdealofficialone/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/quickmyshopdeal" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/myshopdeals/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.youtube.com/@QuickMyshopdeal" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <a class="fxwhatsapp" href="https://api.whatsapp.com/send?phone=919711750505" target="_blank">
            <span class="floatss"><i class="bx bxl-whatsapp"></i></span>Chat With Us</a>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <p>© 2023, All Rights Reserved <a href="https://myshopdeal.co.in/" target="_blank">MyShopDeal</a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>