<div class="page-title-area page-title-style-two item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>All Our Happy Clients</li>
            </ul>
            <h2>All Our Happy Clients</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let item of dataSet; let i = index">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/" class="d-block">
                            <img src="{{fimgURL+item.client_image}}" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <!-- <img src="assets/images/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image"> -->
                                <a routerLink="/" class="d-inline-block">{{item.client_name}}</a>
                            </li>
                            <li>{{item.property_name}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>