import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../../provider/service.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  bg_image: any = 'assets/images/blog_bg.jpeg';
  
  userDataPost={"userID":"", "o_companyID":1, "blogId":""};
  constructor(private route: ActivatedRoute, public service: ServiceService, private sanitizer: DomSanitizer) { 
   // var navParams = JSON.parse(decodeURIComponent(atob(this.route.snapshot.paramMap.get('data'))));
    // const navParams = JSON.parse(this.route.snapshot.paramMap.get('data'));
    this.userDataPost.blogId = this.route.snapshot.paramMap.get('data');
  }

  ngOnInit(): void {
    this.get_blogs_on_blogId();
    this.get_all_published_blogs();
  }

  fslotsData:any; dataSet:any;  fimgURL:any; loading:boolean = false; htmlContent:any;
  get_blogs_on_blogId(){
       this.loading = true;
       this.service.postData(this.userDataPost, 'get_blogs_on_blogId').then((result) => {
         this.fslotsData = result;
         if (this.fslotsData.blogsData){
          this.loading = false;
       this.dataSet = this.fslotsData.blogsData[0];
       this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.fslotsData.blogsData[0].description);
       this.fimgURL = this.fslotsData.fimgURL;
         }else{
        this.loading = false;
       this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
         }
      }, (err) => { 
      this.loading = false;
      this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
      });
    } 


    slotsData:any; blogsSet:any;  imgURL:any; fploading:boolean = false;
    get_all_published_blogs(){
         this.fploading = true;
         this.service.postData(this.userDataPost, 'get_all_published_blogs_limit').then((result) => {
           this.slotsData = result;
           if (this.slotsData.blogsData){
            this.fploading = false;
         this.blogsSet = this.slotsData.blogsData;
         this.imgURL = this.slotsData.fimgURL;
           }else{
          this.fploading = false;
         this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
           }
        }, (err) => { 
        this.fploading = false;
        this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
        });
      } 

/* Alert Message */
show_alert:boolean = false; message:any;
alert(type, message){
  this.show_alert = true;
  this.message={'type':type,'text':message};
  setTimeout(() => {
    this.show_alert = false;
  }, 5000);
}
close_alert(){
  this.show_alert = false;
  this.message = '';
}
/* End of Alert Message */

}
