<section class="events-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">EXPLORE BLOGS</span>
            <h2>Our Latest Insights</h2>
            <p>Keep yourself updated in the real-estate area with Myshopdeal's blogs</p>
        </div>
        <div class="row">
          <ng-container *ngFor="let item of dataSet; let i = index">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <a [routerLink]="'/blog-details/'+item.blogId">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg1">
                                <img src="{{fimgURL+item.image}}" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <h3>{{ (item.title.length>60)? (item.title | slice:0:57)+'...':(item.title) }}</h3>
                                <p>{{ (item.short_description.length>100)? (item.short_description | slice:0:97)+'...':(item.short_description) }}</p>
                                <span class="location"><i class="bx bx-calendar"></i>{{item.postAt}}</span>
                            </div>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
          </ng-container>
            <!-- <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg2">
                                <img src="assets/images/business-coaching/events-img2.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-events">Paper Plates Art</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-calendar"></i>Thu, 04 June, 2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg3">
                                <img src="assets/images/business-coaching/events-img3.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-events">Imagination Classes</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-calendar"></i>Fri, 05 June, 2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg4">
                                <img src="assets/images/business-coaching/events-img4.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-events">Number Matching</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-calendar"></i>Sat, 06 June, 2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="business-shape7"><img src="assets/images/business-shape4.png" alt="image"></div>
</section>