<div class="offer-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Our Values</h2>
            <p>Three vital values that Myshopdeal possess to establish trust and excel in the industry are:</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Integrity</h3>
                    <p>Integrity is the cornerstone of Myshopdeal. Upholding ethical standards, honesty, and transparency in all transactions is crucial.Our  Clients trust that our company and its agents will act in their best interests, disclosing all relevant information and ensuring fairness throughout the buying or selling process.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Customer-Centric Approach</h3>
                    <p>A customer-centric approach is essential in a service-oriented industry. Prioritising the needs and preferences of clients and going the extra mile to provide exceptional service is what sets Myshopdeal apart. Tailoring solutions to meet individual client goals and offering support beyond the transaction creates long-lasting relationships and referrals.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bxs-thermometer'></i>
                    </div>
                    <h3>Expertise and Knowledge</h3>
                    <p>Real estate is a complex field, and Myshopdeal possesses deep industry knowledge and expertise is invaluable to clients. Keeping abreast of market trends, local regulations, and property values demonstrates a commitment to providing the best advice and guidance. Clients seek out our company that can offer insightful information and navigate the intricacies of real estate transactions with confidence and competence.</p>
                </div>
            </div>
        </div>
    </div>
</div>