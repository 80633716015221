import { Component } from '@angular/core';

@Component({
  selector: 'app-career-customer',
  templateUrl: './career-customer.component.html',
  styleUrls: ['./career-customer.component.scss']
})
export class CareerCustomerComponent {

}
