<section class="why-choose-us-area">
    <div class="container-fluid">
        <div class="row bank-block">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h2>Banks For Home Loan</h2>
                </div>
                <div class="bank-image">
                <ng-container *ngFor="let item of dataSet">
                <img src="{{fimgURL+item.bank_logo}}" alt="image">
                </ng-container>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="emi-calculator">
                    <iframe src="https://vmcbl.com/assets/frontend/pages/widget.html" class="iframe" title="EMI Calculator"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/images/lang-shape2.png" alt="image"></div>
</section>