<div class="page-title-area item-bg2" [ngStyle]="{'background-image': 'url(' + bg_image + ')'}">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Home Loan</li>
            </ul>
            <h2>Home Loan</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                        <h2>Home Loan And All you Need To Know</h2>
                        <p>Unlock your homeownership dreams with our hassle-free home loan solutions. We understand that buying a home is a significant milestone, and our tailored home loan options are designed to make it a reality.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/images/home-loan.jpeg" alt="image">
                </div>
                <p>&nbsp;</p>
                <p>Taking the decision to buy a home is difficult and equally difficult is how to take a loan. Also, like any other financial product, it is important to be acquainted with how home loans work to avoid any nasty surprises later. Here are some important things you should know before signing on the dotted line. <br/>Here are some of the things you need to know to before taking a home loan:</p>
                <div class="courses-details-desc">
                    <!-- <h3>What you'll learn</h3> -->
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Employment Stability
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Age Criteria
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Credit Rating
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Employer
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Eligibility
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Balance Transfer
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Tax Benefits
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Multiple Options
                                </span>
                            </li>
                        </ul>
                    </div>

                    <h3>EMPLOYMENT STABILITY</h3>
                    <p>Unless the applicant is salaried and employed for at least 2 years in the current profession or if the applicant is self-employed with minimum 5 years of total earnings loan will not be processed.</p>

                    <h3>AGE CRITERIA</h3>
                    <p>Most banks offer home loan for salaried employees only if they are between the age group of 20 to 60 years. However for self-employed this will change to 24 to 65 years.</p>

                    <h3>CREDIT RATING</h3>
                    <p>Good credit rating will increases the chance of getting the loan with more flexibility on loan amount, EMI, tenure and interest rates.</p>

                    <h3>EMPLOYER</h3>
                    <p>If the applicant is working with an employer who has high reputation and impressive turnover, the credibility of the applicant will respectively increase.</p>

                    <h3>ELIGIBILITY</h3>
                    <p>The standard method banks use to assess your home loan eligibility is the application of FOIR (fixed obligation to income ratio) of a borrower. Many lenders have restricted the FOIR limit to 50% of income.</p>

                    <h3>BALANCE TRANSFER</h3>
                    <p>It is a process by which the loan balance is transferred from one lender to another. It is also referred to as refinancing of your loan. It is not an option for borrowers who have been with a bank for less than 2 years.</p>

                    <h3>TAX BENEFITS</h3>
                    <p>Buying a house through loan comes with several tax benefits. These helps in managing cash flow along with reduction to taxes and help you maximize your savings on your investment.</p>

                    <h3>MULTIPLE OPTIONS</h3>
                    <p>With Myshopdeal team assistance you may have the option to shortlist a bucket of banks options to compare and shortlist the one who meet your requirements the most .</p>

                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <img src="assets/images/homeloan_Icon-01.png" />
                    <h4>GET THE BEST OFFER</h4>
                    <p>We provide best offers available in the market so that you do not have to go anywhere else.</p>
                </div>

                <div class="courses-sidebar-information">
                    <img src="assets/images/homeloan_Icon-02.png" />
                    <h4>HOUSES</h4>
                    <p>We provide best offers available in the market so that you do not have to go anywhere else.</p>
                </div>

                <div class="courses-sidebar-information">
                    <img src="assets/images/homeloan_Icon-03.png" />
                    <h4>COMMERCIAL</h4>
                    <p>We provide best offers available in the market so that you do not have to go anywhere else.</p>
                </div>

                <div class="courses-sidebar-information">
                    <img src="assets/images/homeloan_Icon-04.png" />
                    <h4>LAND</h4>
                    <p>We provide best offers available in the market so that you do not have to go anywhere else.</p>
                </div>
                <aside class="widget-area">
                <div class="widget widget_contact">
                    <div class="text">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <span>Need Help ?</span>
                        <a href="tel:+919711750505">+91-9711750505</a>
                    </div>
                </div>
                </aside>
            </div>
        </div>
    </div>
</div>

<div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container-fluid">
<app-banks></app-banks>
</div>
</div>