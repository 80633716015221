<div class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Become a Client</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                    <a routerLink="/contact" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1">
                    <img src="assets/images/about-client.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2">
                    <img src="assets/images/about-patner.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Become a Partner</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                    <a routerLink="/contact" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>