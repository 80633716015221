import { Component } from '@angular/core';

@Component({
  selector: 'app-career-leadership',
  templateUrl: './career-leadership.component.html',
  styleUrls: ['./career-leadership.component.scss']
})
export class CareerLeadershipComponent {

}
