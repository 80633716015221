import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ServiceService } from '../../provider/service.service';

@Component({
    selector: 'app-happy-students-feedback',
    templateUrl: './happy-students-feedback.component.html',
    styleUrls: ['./happy-students-feedback.component.scss']
})
export class HappyStudentsFeedbackComponent implements OnInit {
    userDataPost={"userID":"", "o_companyID":1};
    constructor(public service: ServiceService) { }
  
    ngOnInit(): void {
      this.get_all_testimonials();
    }
  
    fslotsData:any; dataSet:any;  fimgURL:any; loading:boolean = false;
    get_all_testimonials(){
         this.loading = true;
         this.service.postData(this.userDataPost, 'get_all_testimonials').then((result) => {
           this.fslotsData = result;
           if (this.fslotsData.testimonialsData){
            this.loading = false;
         this.dataSet = this.fslotsData.testimonialsData;
         this.fimgURL = this.fslotsData.fimgURL;
           }else{
          this.loading = false;
         this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.');
           }
        }, (err) => { 
        this.loading = false;
        this.alert('error', 'Opps! Something went wrong. Kindly Contact to Technical Team.'); 
        });
      } 
  
  
  /* Alert Message */
  show_alert:boolean = false; message:any;
  alert(type, message){
    this.show_alert = true;
    this.message={'type':type,'text':message};
    setTimeout(() => {
      this.show_alert = false;
    }, 5000);
  }
  close_alert(){
    this.show_alert = false;
    this.message = '';
  }
  /* End of Alert Message */

    testimonialsWrapSlides: OwlOptions = {
        dots: true,
        margin: 30,
		loop: true,
        nav: false,
        autoplay: true,
        stagePadding: 50,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                stagePadding: 0
            },
            576: {
                items: 1,
                stagePadding: 0
            },
            768: {
                items: 2,
                stagePadding: 0
            },
            1200: {
                items: 3,
            }
        }
    }

}